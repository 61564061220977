import React, { useEffect } from 'react';
import { useAnalytics } from '@chordcommerce/react-autonomy';
import { WindowLocation } from '@reach/router';

interface PageTrackerProps {
  location: WindowLocation;
}

const PageTracker: React.FC<PageTrackerProps> = ({ location }) => {
  const { chordAnalytics } = useAnalytics();

  useEffect(() => {
    const id = setTimeout(() => {
      window.analytics?.ready?.(() => {
        chordAnalytics.page(document.title);
      });
    }, 50); // Using default delay of 50ms as in the Gatsby theme

    return () => clearTimeout(id);
  }, [location, chordAnalytics]);

  return null;
};

export default PageTracker;
