import { Box, Flex, Text, ThemeUIStyleObject } from 'theme-ui';
import { useTranslate } from '~/hooks/utils/use-translate';
import { OrderBig } from '@chordcommerce/react-autonomy';
import buyNowPayLaterCalculate from '~/utils/buy-now-pay-later';
import { getAllCartPromotionsForDisplay } from '~/utils/promotions';
import formatMoney from '~/utils/format-money';

type RowWrapperProps = {
  children: React.ReactNode;
  forwardSx?: ThemeUIStyleObject;
};

const RowWrapper = ({ children, forwardSx }: RowWrapperProps) => (
  <Flex
    sx={{
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      textAlign: 'left',
      my: '.15rem',
      ...forwardSx,
    }}
  >
    {children}
  </Flex>
);

type Props = {
  cart: Partial<OrderBig>;
};

const OrderConfirmationSummary = ({ cart = {} }: Props) => {
  const translate = useTranslate();
  const {
    displayItemTotal,
    displayTaxTotal,
    displayShipTotal,
    giftCardPayments,
    totalApplicableGiftCard,
    total,
    displayTotalApplicableGiftCard,
  } = cart;

  const promotions = getAllCartPromotionsForDisplay(cart);

  const sum = promotions.reduce((sum, y) => sum + y.amount, 0);

  const displayTotalWithGiftCard = formatMoney(Number(total ?? 0) - Number(totalApplicableGiftCard ?? 0), {
    locale: 'en',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <Flex
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: ['100%', '60%'],
        mb: ['2.5rem', '5.5rem'],
        fontSize: ['initial', '1.9rem'],
      }}
    >
      {displayItemTotal && (
        <RowWrapper forwardSx={{ mt: '0 !important' }}>
          <Text as="p">{translate('cart.subtotal')}</Text>
          <Text as="p" sx={{ fontWeight: 'medium' }}>
            {displayItemTotal}
          </Text>
        </RowWrapper>
      )}

      {promotions.length > 0 && (
        <RowWrapper>
          <Text as="p">Promotions</Text>

          <Text as="p" sx={{ fontWeight: 'medium' }}>
            {buyNowPayLaterCalculate(sum, 1)}
          </Text>
        </RowWrapper>
      )}
      {giftCardPayments && giftCardPayments?.length > 0 && (
        <RowWrapper>
          <Text as="p">Gift Card </Text>
          <Text as="p" sx={{ fontWeight: 'medium' }}>
            -{displayTotalApplicableGiftCard}
          </Text>
        </RowWrapper>
      )}

      {displayShipTotal && (
        <RowWrapper>
          <Text as="p">Shipping</Text>
          <Text as="p" sx={{ fontWeight: 'medium' }}>
            {displayShipTotal}
          </Text>
        </RowWrapper>
      )}

      {displayTaxTotal && (
        <RowWrapper>
          <Text as="p">{translate('cart.tax')}</Text>
          <Text as="p" sx={{ fontWeight: 'medium' }}>
            {displayTaxTotal}
          </Text>
        </RowWrapper>
      )}

      <Box sx={{ borderBottom: '1px solid #5C604F20', my: ['2.5rem', '3.5rem'] }} />

      <RowWrapper forwardSx={{ p: { fontSize: ['2rem', '2.8rem'] } }}>
        <Text as="p">{translate('cart.total')}</Text>
        <Text as="p" sx={{ fontWeight: 'medium' }}>
          {displayTotalWithGiftCard}
        </Text>
      </RowWrapper>
    </Flex>
  );
};

OrderConfirmationSummary.defaultProps = {
  cart: null,
};

export default OrderConfirmationSummary;
