import React, { FC, useEffect, useState } from 'react';
import { Flex, Grid, Text } from 'theme-ui';
import ImgLoader from '../../Generic/ImgLoader';
import ShopAllColorSwatch from './ColorSwatch';
import { TransformedProduct } from '~/types/filter';
import ProductLink from '../../Product/Link';
import { useBadge } from '~/hooks/components/use-badge';
import { ShopFilterState } from '../../Generic/Filter';
import useProductPrice from '~/hooks/components/use-product-price';
import Price from './Price';
import RegularPrice from './RegularPrice';
import ProductOrBundleTitle from './Title';

type Props = {
  product: TransformedProduct;
  current: ShopFilterState;
};

type Variant = Queries.VariantDetailsFragmentFragment;

const ShopAllProductCard: FC<Props> = ({ product, current }) => {
  const { shopAllFeatured, badges } = product;
  const [currentlyHoveredVariant, setCurrentlyHoveredVariant] = useState<Variant | null>(null);
  const [filteredColorVariant, setFilteredColorVariant] = useState<Variant | null>(null);
  const [currentlyHoveredOptionValue, setCurrentlyHoveredOptionValue] =
    useState<Queries.OptionValuesFragmentFragment | null>(null);
  const [colorQuery, setColorQuery] = useState<string | null>(null);
  const badge = useBadge({ selectedOption: currentlyHoveredOptionValue, badges: badges as Queries.BadgeFragment[] });
  const price = useProductPrice(product);

  const handleColorSwatchHover = (optionValue: Queries.OptionValuesFragmentFragment) => {
    setCurrentlyHoveredOptionValue(optionValue);
    const variant = product?.variants?.find((v) => v?.optionValues?.find((o) => o?.slug === optionValue?.slug));
    if (variant) setCurrentlyHoveredVariant(variant);
    setFilteredColorVariant(null);
    const search = new URLSearchParams();
    if (optionValue?.presentation) {
      // If the user hovers over a color swatch, update the URL query string
      search.set('color', optionValue.presentation);
      setColorQuery(search.toString());
    }
  };

  const filterQuery = new URLSearchParams(location.search).get('filter')?.replace('/', '') ?? null;

  useEffect(() => {
    const selectedFilteredColor = current.filter.colors[0];

    // First pass: Look for exact matches
    let filteredVariant = product?.variants?.find((v) =>
      v?.optionValues?.find((o) => o?.slug === selectedFilteredColor?.slug)
    );

    // Second pass: If no exact match, look for accent color matches
    if (!filteredVariant) {
      filteredVariant = product?.variants?.find((v) =>
        v?.optionValues?.find((o) => {
          const colorParts = o?.slug?.split('-');
          if (!colorParts || !o?.slug?.startsWith('color-')) return false;

          if (colorParts.length === 3) {
            const [, mainColor, accentColor] = colorParts;
            return (
              selectedFilteredColor?.slug === `color-${accentColor}` ||
              selectedFilteredColor?.slug === `color-${mainColor}`
            );
          }
          return false;
        })
      );
    }

    const filteredOptionValue =
      filteredVariant?.optionValues?.find((o) => {
        if (o?.slug === selectedFilteredColor?.slug) return true;
        const colorParts = o?.slug?.split('-');
        if (colorParts?.length === 3) {
          const [, , accentColor] = colorParts;
          return selectedFilteredColor?.slug === `color-${accentColor}`;
        }
        return false;
      }) ?? null;

    if (filteredVariant && filterQuery) {
      setFilteredColorVariant(filteredVariant);
      setCurrentlyHoveredOptionValue(filteredOptionValue);
    } else if (!filteredVariant) {
      setFilteredColorVariant(null);
      setCurrentlyHoveredOptionValue(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterQuery]);

  const slug = colorQuery ? `${product.slug}?${colorQuery}` : product.slug;

  const maxRegularPrice = Math.max(...(product.variants?.map((v) => v?.regularPrice ?? 0) ?? [0]));
  const isOnSale = maxRegularPrice - product.filterData.price !== 0;
  const isGiftCard = product.slug?.includes('gift-card');
  return (
    <ProductLink
      productSlug={slug}
      collectionSlug={product.collection?.[0]?.slug}
      productName={product.name}
      locationDetail="Shop All"
      sx={{
        maxWidth: ['100%', '500px'],
        gridColumn: shopAllFeatured ? ['span 2', 'auto'] : 'auto',
        isolation: 'isolate',
        position: 'relative',
      }}
    >
      <Grid sx={{ gap: '0.8rem' }}>
        <ImgLoader
          image={
            filteredColorVariant?.mainImage ?? currentlyHoveredVariant?.mainImage ?? product.additionalContentImage
          }
          badge={badge}
          forwardSx={{
            aspectRatio: ['16/19', '1/1'],
          }}
        />

        <Flex sx={{ gap: ['.6rem', '.8rem'], flexDirection: 'column' }}>
          <ShopAllColorSwatch
            product={product}
            forwardSx={{ gap: '2xs', '--dots': '1.6rem', py: '.2rem', mb: '.2rem' }}
            handleColorSwatchHover={handleColorSwatchHover}
            isGiftCard={isGiftCard}
          />

          <Flex sx={{ justifyContent: 'space-between', mt: isGiftCard ? '.2rem' : 0 }}>
            <ProductOrBundleTitle title={product.longTitle} />
            <Price price={price} forwardSx={{ display: ['none', 'block'] }} />
          </Flex>

          {!isGiftCard && (
            <Flex sx={{ justifyContent: 'space-between' }}>
              <Text
                as="div"
                sx={{
                  justifySelf: [shopAllFeatured ? 'end' : 'start', 'end'],
                  fontSize: ['1.4rem', '1.6rem'],
                  fontWeight: 400,
                  lineHeight: '1.6rem',
                }}
              >
                {product.microloadHighlight?.replace(/microload/i, '')}
              </Text>
              {isOnSale && <RegularPrice regularPrice={maxRegularPrice} forwardSx={{ display: ['none', 'block'] }} />}
            </Flex>
          )}
          <Flex
            sx={{
              display: ['flex', 'none'],
              gap: '0.8rem',
            }}
          >
            <Price price={price} />
            {isOnSale && <RegularPrice regularPrice={maxRegularPrice} />}
          </Flex>
        </Flex>
      </Grid>
    </ProductLink>
  );
};

export default ShopAllProductCard;
