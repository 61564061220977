import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import ProductPage from '~/components/Product/Page';

// Define types for the data prop
interface ProductData {
  product: Queries.ProductPageFragmentFragment;
  notifications: {
    pdpNotifications: Queries.NotificationFragmentFragment[];
  };
  productCollection: {
    nodes: Queries.ProductCollectionFragment[];
  };
}

const ProductTemplate: React.FC<PageProps<ProductData>> = ({
  location,
  data: { product, notifications, productCollection },
}) => {
  const collectionArray = product.collection;

  const price = product.variants?.[0]?.price;

  const collection = collectionArray?.find((c) => location.pathname.includes(c?.slug ?? ''));

  return (
    <Layout navColor={true}>
      <Metadata
        collection={`${collection?.name} Gravity Sportswear`}
        title={product.metaTitle ?? product.name}
        description={product.metaDescription ?? product.shortDescription}
        image={product.variants?.[0]?.mainImage?.file?.url}
      >
        <script type="application/ld+json">
          {`{
    "@context": "https://schema.org",
    "@type": "Product",
    "description":
      "${product.metaDescription ?? product.shortDescription}",
    "name": "${product.metaTitle ?? product.name}",
    "image": "${product.variants?.[0]?.mainImage?.file?.url}",
    "offers": {
      "@type": "Offer",
      "availability": "https://schema.org/InStock",
      "price": "${price}",
      "priceCurrency": "USD"
    }
  }`}
        </script>
      </Metadata>

      <ProductPage
        notifications={notifications}
        product={product}
        collection={collection}
        productCollection={productCollection?.nodes}
      />
    </Layout>
  );
};

export const query = graphql`
  query ProductPage($slug: String, $locale: String) {
    product: contentfulProduct(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...ProductPageFragment
    }
    notifications: contentfulSiteMetadata(slug: { eq: "site-metadata" }) {
      pdpNotifications {
        ...NotificationFragment
      }
    }
    productCollection: allContentfulProduct(
      filter: {
        collection: { elemMatch: { products: { elemMatch: { slug: { eq: $slug } } } } }
        hideOnShopAll: { ne: true }
      }
    ) {
      nodes {
        ...ProductCollection
      }
    }
  }
`;

export default ProductTemplate;
