import React from 'react';
import { Text } from 'theme-ui';

type Props = {
  title: string | null | undefined;
};

const ProductOrBundleTitle = ({ title }: Props) => {
 

  return (
    <Text
      sx={{
        width: 'fit-content',
        fontSize: ['1.6rem', '1.8rem'],
        lineHeight: 1,
        fontWeight: 500,
        letterSpacing: '-0.01em',
        textTransform: 'capitalize',
      }}
      dangerouslySetInnerHTML={{ __html: title?.replaceAll('&nbsp;', ' ') ?? '' }}
    />
  );
};

export default ProductOrBundleTitle;
