import { useState, useEffect } from 'react';
type SelectedOption =
  | Queries.OptionValuesFragmentFragment
  | Queries.VariantDetailsFragmentFragment
  | Queries.VariantCardFragmentFragment
  | null
  | undefined;

type Args = {
  selectedOption: SelectedOption;
  badges: (Queries.BadgeFragment | null | undefined)[] | null | undefined;
  isQuickshop?: boolean;
};

export const useBadge = ({ selectedOption, badges, isQuickshop }: Args) => {
  const [badge, setBadge] = useState<Queries.BadgeFragment | null>(null);

  useEffect(() => {
    if (!badges?.length) return;

    if (!badges[0]?.optionValues) {
      setBadge(badges?.[0] ?? null);
      return;
    }
    let newBadge;
    if (selectedOption) {
      if (isOptionValue(selectedOption)) {
        newBadge = badges?.find((badge) =>
          badge?.optionValues?.find((optionValue) => optionValue?.slug === selectedOption?.slug)
        );
      }
      if (isVariant(selectedOption)) {
        const badgeOptionValues = badges.flatMap((badge) => badge?.optionValues ?? []);
        const colors = badgeOptionValues.filter((ov) => ov?.slug?.includes('color'));
        const sizes = badgeOptionValues.filter((ov) => ov?.slug?.includes('size'));

        if (colors.length > 0 && sizes.length > 0) {
          newBadge = badges?.find((badge) => {
            const color = badge?.optionValues?.find(
              (optionValue) =>
                optionValue?.slug === selectedOption?.optionValues?.find((ov) => ov?.slug?.includes('color'))?.slug
            );
            const size = badge?.optionValues?.find(
              (optionValue) =>
                optionValue?.slug === selectedOption?.optionValues?.find((ov) => ov?.slug?.includes('size'))?.slug
            );
            return color && size;
          });
        } else {
          newBadge = badges?.find((badge) =>
            badge?.optionValues?.find((optionValue) => {
              const optionValueType = optionValue?.slug?.split('-')[0];
              if (!optionValueType) return false;

              return (
                optionValue?.slug ===
                selectedOption?.optionValues?.find((ov) => ov?.slug?.includes(optionValueType))?.slug
              );
            })
          );
        }
      }

      setBadge(newBadge ?? null);
    } else {
      const defaultBadge = badges.find((badge) => badge?.showOnDefaultImage);
      if (defaultBadge && !isQuickshop) {
        setBadge(defaultBadge);
      }
    }
  }, [selectedOption, badges, isQuickshop]);

  return badge;
};

const isOptionValue = (option: SelectedOption): option is Queries.OptionValuesFragmentFragment => {
  return (option as Queries.OptionValuesFragmentFragment).presentation !== undefined;
};

const isVariant = (
  option: SelectedOption
): option is Queries.VariantDetailsFragmentFragment | Queries.VariantCardFragmentFragment => {
  return (option as Queries.VariantDetailsFragmentFragment).optionValues !== undefined;
};
