import React, { MouseEvent, useMemo, Dispatch, SetStateAction } from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import InputWithPlaceholder from '~/components/Generic/Form/InputWithPlaceholder';
import { Checkbox, Label } from 'theme-ui';

type Props = {
  showGiftCardForm: boolean;
  register: UseFormRegister<GiftCardFormValues>;
  errors: FieldErrors<GiftCardFormValues>;
  setSendLater: Dispatch<SetStateAction<boolean>>;
  sendLater: boolean;
};

export type GiftCardFormValues = {
  recipientName: string;
  recipientEmail: string;
  purchaserName: string;
  giftMessage: string;
  sendEmailAt: string;
  sendLater: boolean;
};

export type FormField = {
  name: keyof GiftCardFormValues;
  placeholder: string;
  required: boolean;
  type: string;
  message?: string;
  onClick?: (e: MouseEvent<HTMLInputElement>) => void;
};

const formFields: FormField[] = [
  {
    name: 'recipientName',
    placeholder: 'Recipient Name',
    required: false,
    type: 'text',
    message: 'Please enter a valid name',
  },
  {
    name: 'recipientEmail',
    placeholder: 'Recipient Email',
    required: true,
    type: 'email',
    message: 'Please enter a valid email',
  },
  {
    name: 'purchaserName',
    placeholder: 'Your Name',
    required: false,
    type: 'text',
    message: 'Please enter a valid name',
  },
  {
    name: 'giftMessage',
    placeholder: 'Gift Message',
    required: false,
    type: 'textarea',
    message: 'Please enter a valid message',
  },
  {
    name: 'sendEmailAt',
    placeholder: 'Send Email Date',
    required: false,
    type: 'date',
    message: 'Please select a date between today and 90 days from now',
  },
];

const GiftCardForm = ({ showGiftCardForm, register, errors, setSendLater, sendLater }: Props) => {
  const handleDateClick = (e: MouseEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    if (input.type === 'date' && typeof input.showPicker === 'function') {
      input.showPicker();
    }
  };
  const { minDate, maxDate, validateDate } = useMemo(() => {
    const minDate = new Date().toISOString().split('T')[0];
    const maxDate = new Date(new Date().setDate(new Date().getDate() + 90)).toISOString().split('T')[0];
    const validateDate = (value: string) => {
      if (!value) return true; // Allow empty value if not required
      return value >= minDate && value <= maxDate;
    };
    return { minDate, maxDate, validateDate };
  }, []);

  return (
    <div sx={{ display: 'flex', flexDirection: 'column', gap: '1.6rem' }}>
      {showGiftCardForm &&
        formFields.map((field) => {
          if (field.name === 'sendEmailAt') {
            return (
              <div
                key={field.name}
                sx={{
                  display: 'grid',
                  gap: '1.6rem',
                  gridTemplateColumns: '1fr max-content',
                  alignItems: 'center',
                  minHeight: '4rem',
                }}
              >
                <Label>
                  <Checkbox {...register('sendLater')} onChange={(e) => setSendLater(e.target.checked)} />
                  Send later?
                </Label>
                {sendLater && (
                  <InputWithPlaceholder
                    theme="light"
                    {...register(field.name, {
                      required: sendLater && field.required && field.message,
                      validate: validateDate,
                      min: minDate,
                      max: maxDate,
                    })}
                    error={errors[field.name]?.message}
                    placeholder={field.placeholder}
                    type={field.type}
                    onClick={handleDateClick}
                    min={minDate}
                    max={maxDate}
                  />
                )}
              </div>
            );
          }
          return (
            <InputWithPlaceholder
              theme="light"
              key={field.name}
              {...register(field.name, {
                required: field.required && field.message,
              })}
              error={errors[field.name]?.message}
              placeholder={field.placeholder}
              type={field.type}
            />
          );
        })}
    </div>
  );
};

export default GiftCardForm;
