export const ColorComponent = ({ size, color }: { size: 'xs' | 'sm'; color: string }) => {
  if (size === 'xs')
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none" sx={{ color }}>
        <circle cx="8" cy="8" r="7" fill="currentColor" />
      </svg>
    );

  if (size === 'sm')
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 26 26" fill="none" sx={{ color }}>
        <circle cx="13" cy="13" r="12" fill="currentColor" stroke={`${color === '#000000' ? 'white' : 'none'}`} />
      </svg>
    );

  return null;
};
export default ColorComponent;
