import { PropsWithChildren, createContext, useContext, useEffect, useMemo, useState } from 'react';
import useProductsAndKitsWithFilterQuery from '~/hooks/graphql/queries/use-products-and-kits-with-filter-query';
import useCustomSortTitle from '~/hooks/graphql/queries/use-custom-sort-title-query';
import {
  FilterObject,
  FilterObjectWithAlternate,
  SortDirection,
  SortKeys,
  SortObject,
  SortType,
  TransformedBundle,
  TransformedBundleOrProductOrKit,
  TransformedKit,
  TransformedProduct,
  TransformedSortData,
} from '~/types/filter';
import useAllFilterOptionsQuery from '~/hooks/graphql/queries/use-all-filter-options';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
// TODO: Refactor with useReducer

const BUNDLES_ONLY_SLUG = 'bundles-only';
const BUNDLES_EXCLUDE_SLUG = 'exclude-bundles';

type FilterOptionsObject = {
  gender: FilterObject<'mens' | 'womens' | 'all'>[];
  collection: FilterObject[];
  sizes: FilterObject[];
  colors: FilterObjectWithAlternate[];
  bundlesOnly: boolean;
  excludeBundles: boolean;
};

export type SelectedFilterOptions = {
  gender: FilterObject<'mens' | 'womens' | 'all'>;
  collection: FilterObject[];
  sizes: FilterObject[];
  colors: FilterObjectWithAlternate[];
  bundlesOnly: boolean;
  excludeBundles: boolean;
};
export type ShopFilterState = {
  // These are available options
  filteredProducts: TransformedBundleOrProductOrKit[];
  sort: SortType;
  // isLoading: boolean;
  // This is currently selected options
  filter: SelectedFilterOptions;
};
const initialGender: FilterObject<'mens' | 'womens' | 'all'> = { slug: 'all', title: 'All' };

export const genderOptions: FilterObject<'mens' | 'womens' | 'all'>[] = [
  initialGender,
  { slug: 'mens', title: 'Mens' },
  { slug: 'womens', title: 'Womens' },
];

export type PartialReturnedShopFilter = Partial<{
  filter: Partial<SelectedFilterOptions>;
  sort: Partial<SortType>;
}>;

export type FilteredKeys = keyof ShopFilterState['filter'];

type RemoveFilterFunction = <K extends FilteredKeys>(
  props: ShopFilterState['filter'][K] extends boolean
    ? {
        property: K;
        slug?: string;
      }
    : {
        property: K;
        slug: string;
      }
) => void;

type FilterFunction = <K extends FilteredKeys>({
  property,
  payload,
}: {
  property: K;
  payload: ShopFilterState['filter'][K] extends boolean ? boolean : FilterObject;
}) => void;

export interface ShopFilterContext {
  // add your context properties here
  state: {
    dirty: boolean;
    saved: ShopFilterState;
    current: ShopFilterState;
    // isLoading: boolean;
    availableSortOptions: SortType[];
    availableFilterOptions: FilterOptionsObject;
  };
  methods: {
    /**
     * Retrieves all products of last saved state from filter
     */
    sort: (action: SortType) => void;
    filter: FilterFunction;
    remove: RemoveFilterFunction;
    clear: () => void;
    loadPrevious: () => void;
    // setIsLoading: (arg: boolean) => void;
    save: () => void;
  };
}

const shopFilterContext = createContext<ShopFilterContext | null>(null);
/**
 * Go through filter object and return a query string.
 * each option is separated by '--'.
 * gender first, then collection, then sizes, then colors, then bundlesOnly,
 * finally sort value and order.
 *
 * Example:
 * 'mens--holiday-deals--gravity-apparel--XS--S--color-ocean--color-fire--bundles-only';
 *
 */
const generateFilterQueryString = (filter: SelectedFilterOptions): string => {
  const filterOptionStringArray: string[] = Object.keys(filter).reduce((acc: string[], key) => {
    let arrayToAdd = [];
    if (key === 'bundlesOnly') {
      if (filter[key]) {
        arrayToAdd.push(BUNDLES_ONLY_SLUG);
      }
    } else if (key === 'excludeBundles') {
      if (filter[key]) {
        arrayToAdd.push(BUNDLES_EXCLUDE_SLUG);
      }
    } else if ((key === 'colors' || key === 'sizes' || key === 'collection') && Array.isArray(filter[key])) {
      const array = filter[key];
      arrayToAdd.push(...array.map((item) => item.slug));
    } else if (key === 'gender') {
      const filterObject = filter[key];
      arrayToAdd.push(filterObject.slug);
    }
    acc.push(...arrayToAdd);
    return acc;
  }, []);
  return filterOptionStringArray.join('--');
};

const generateSortQueryString = (sort: SortType): string => {
  if (sort.by.value === SortKeys.CUSTOM) return ''; // Don't return a value for sort if CUSTOM because that's default
  if (sort.by.value && sort.order) return `${sort.by.value}--${sort.order}`;
  return '';
};

const generateQueryString = (filter: SelectedFilterOptions, sort: SortType): string => {
  const sortQuery = generateSortQueryString(sort);
  const filterQuery = generateFilterQueryString(filter);
  if (filterQuery && sortQuery) return `${filterQuery}--${sortQuery}`;
  if (filterQuery) return filterQuery;
  if (sortQuery) return sortQuery;
  return '';
};

const ShopFilterProvider = ({ children, filterQuery }: PropsWithChildren<{ filterQuery: string | null }>) => {
  const customSortTitle = useCustomSortTitle();
  const location = useLocation();

  // Layout possible sort options
  const customSort: SortObject = useMemo(() => ({ title: customSortTitle, value: SortKeys.CUSTOM }), [customSortTitle]);

  const initialSort = useMemo(() => ({ by: customSort, order: SortDirection.DESC }), [customSort]);

  const possibleSortOptions: SortType[] = [
    initialSort,
    { by: { title: 'Price', value: SortKeys.PRICE }, order: SortDirection.ASC },
    { by: { title: 'Price', value: SortKeys.PRICE }, order: SortDirection.DESC },
    { by: { title: 'Weight', value: SortKeys.WEIGHT }, order: SortDirection.DESC },
    { by: { title: 'Weight', value: SortKeys.WEIGHT }, order: SortDirection.ASC },
  ];

  const { categoryCollectionFilter, colorOptionValues, sizeOptionValues } = useAllFilterOptionsQuery();

  // Layout possible filter options
  const possibleFilterOptions: FilterOptionsObject = {
    gender: genderOptions,
    collection: categoryCollectionFilter,
    sizes: sizeOptionValues,
    colors: colorOptionValues,
    bundlesOnly: false,
    excludeBundles: false,
  };

  // Go through query string, and return filter object
  const getFilterSortFromQueryString = (
    filterQueryString: string
  ): { filter: SelectedFilterOptions; sort: SortType } => {
    const filterSortQueryArray = filterQueryString.split('--');

    const filter = filterSortQueryArray.reduce((acc, value: string) => {
      for (let key in possibleFilterOptions) {
        let typedKey = key as keyof FilterOptionsObject;
        if (value === BUNDLES_ONLY_SLUG && typedKey === 'bundlesOnly') {
          acc[typedKey] = value === BUNDLES_ONLY_SLUG;
        } else if (value === BUNDLES_EXCLUDE_SLUG && typedKey === 'excludeBundles') {
          acc[typedKey] = value === BUNDLES_EXCLUDE_SLUG;
        } else if (Array.isArray(possibleFilterOptions[typedKey])) {
          const filterObjectValue = possibleFilterOptions[typedKey as 'sizes' | 'collection' | 'gender'].find(
            (item) => item.slug === value
          );
          if (filterObjectValue) {
            if (typedKey === 'gender') {
              acc[typedKey] = (filterObjectValue ?? initialGender) as FilterObject<'all' | 'mens' | 'womens'>;
            } else {
              // Colors filter will go here
              if (!Object.prototype.hasOwnProperty.call(acc, typedKey))
                (acc[typedKey] as (FilterObject | FilterObjectWithAlternate)[]) = [];
              (acc[typedKey] as (FilterObject | FilterObjectWithAlternate)[]).push(filterObjectValue);
            }
          }
        }
      }

      return acc;
    }, {} as SelectedFilterOptions);

    // Get last two items in array, these correspond to sort object
    const sortOptions = filterSortQueryArray.splice(filterSortQueryArray.length - 2);

    const sort = possibleSortOptions.find((sortOption) => {
      if (sortOptions.length === 2) {
        return sortOption.by.value === sortOptions[0] && sortOption.order === sortOptions[1];
      }
      return null;
    });

    return { filter, sort: sort ?? initialSort };
  };

  // Get all products and set up state
  const products: (TransformedProduct | TransformedKit | TransformedBundle)[] = useProductsAndKitsWithFilterQuery();

  const initialState: ShopFilterState = useMemo(() => {
    return {
      filteredProducts: products,
      sort: initialSort,
      filter: {
        gender: initialGender,
        collection: [],
        sizes: [],
        colors: [],
        bundlesOnly: false,
        excludeBundles: false,
      },
    };
  }, [products, initialSort]);

  const [shopFilterSort, setShopFilterSort] = useState<{ saved: ShopFilterState; current: ShopFilterState }>({
    // if filterQuery object exists, override while maintaining initialState for later reset

    saved: {
      ...initialState,
      filter: { ...initialState.filter },
      sort: {
        ...initialState.sort,
      },
    },
    current: {
      ...initialState,
      filter: { ...initialState.filter },
      sort: {
        ...initialState.sort,
      },
    },
  });

  const clearQuery = async () => {
    await navigate('/shop-all', { replace: true });
  };

  useEffect(() => {
    // On mount, set up products by filter and sort
    if (filterQuery) {
      const { filter, sort } = getFilterSortFromQueryString(filterQuery ?? '');

      setShopFilterSort((prevState) => {
        const sortedProducts = handleSortProducts({ ...initialState.sort, ...sort }, products);
        const filtered = handleFilterProducts(sortedProducts, { ...initialState.filter, ...filter });

        const combinedFilter = { ...initialState.filter, ...filter };
        const combinedSort = { ...initialState.sort, ...sort };

        return {
          ...prevState,
          saved: { ...prevState.saved, filter: combinedFilter, filteredProducts: filtered, sort: combinedSort },
          current: { ...prevState.current, filter: combinedFilter, filteredProducts: filtered, sort: combinedSort },
        };
      });
    } else {
      setShopFilterSort({
        saved: { ...initialState },
        current: { ...initialState },
      });
    }
  }, [filterQuery]);

  const saveToQuery = async (filter: SelectedFilterOptions, sort: SortType) => {
    const url = new URL(location.href);
    url.searchParams.set('filter', generateQueryString(filter, sort));
    await navigate(url.pathname + url.search, { replace: true });
  };

  const checkIfQueryMatch = (saved: ShopFilterState, initial: ShopFilterState) => {
    const initialSortString = JSON.stringify({ sort: initial.sort, filter: initial.filter });
    const currentSavedString = JSON.stringify({ sort: saved.sort, filter: saved.filter });
    return initialSortString === currentSavedString;
  };

  const handleClearOrSaveQuery = (saved: ShopFilterState) => {
    // On save, set up filter query param
    if (checkIfQueryMatch(saved, initialState)) {
      // If the saved state is the same as the initial state, remove query param
      clearQuery();
      return;
    }
    saveToQuery(saved.filter, saved.sort);
  };

  const handleSortProducts = ({ by, order }: SortType, products: TransformedBundleOrProductOrKit[]) => {
    let newSortedProducts = [...products];

    const currentSortType = by.value;
    // Only let this be property of TransformedBundleOrProductOrKit
    // where the value of the property is a number
    let sortProperty: keyof TransformedSortData = 'customValue';
    switch (currentSortType) {
      case SortKeys.CUSTOM:
        sortProperty = 'customValue';
        break;
      case SortKeys.PRICE:
        sortProperty = 'price';
        break;
      case SortKeys.WEIGHT:
        sortProperty = 'ounceWeight';
        break;
      default:
        const exhaustiveCheck: never = currentSortType;
        console.warn(exhaustiveCheck);
        break;
    }
    newSortedProducts = newSortedProducts.sort((a, b) => {
      const valueA = a.filterData[sortProperty];
      const valueB = b.filterData[sortProperty];
      let sortedValue = 0;
      if (order === SortDirection.ASC) {
        sortedValue = valueA - valueB;
      } else {
        sortedValue = valueB - valueA;
      }

      if (sortedValue === 0) {
        return a.filterData.isBundle ? -1 : 1;
      }
      return sortedValue;
    });
    return [...newSortedProducts];
  };

  const handleFilterProducts = (
    products: TransformedBundleOrProductOrKit[],
    currentFilterSettings: SelectedFilterOptions
  ) => {
    // Filter by each property in currentFilter
    let newFilteredProducts = [...products];

    if (currentFilterSettings.bundlesOnly) {
      newFilteredProducts = newFilteredProducts.filter((product) => product.filterData.isBundle);
    }

    if (currentFilterSettings.excludeBundles) {
      newFilteredProducts = newFilteredProducts.filter((product) => !product.filterData.isBundle);
    }

    // Filter products by collection (any collection in array)
    // No duplicate products
    if (currentFilterSettings.collection.length > 0) {
      const collectionSlugs = currentFilterSettings.collection.map((collection) => collection.slug);
      newFilteredProducts = newFilteredProducts.filter((product) =>
        product.filterData.collections.find((slug) => collectionSlugs.includes(slug))
      );
    }

    // Filter products by size (any size in array)
    // No duplicate products
    if (currentFilterSettings.sizes.length > 0) {
      const sizeSlugs = currentFilterSettings.sizes.map((size) => size.slug);
      newFilteredProducts = newFilteredProducts.filter((product) => {
        return product.filterData.sizes.find((slug) => sizeSlugs.includes(slug));
      });
    }

    // Filter products by color (any color in array)
    // No duplicate products
    if (currentFilterSettings.colors.length > 0) {
      const [colorSlug] = currentFilterSettings.colors.map((color) => color.slug);
      newFilteredProducts = newFilteredProducts.filter((product) => {
        return product.filterData.colors.find((slug) => {
          const regex = new RegExp(`${colorSlug.replace('color-', '')}`);
          return regex.test(slug);
        });
      });
    }

    newFilteredProducts = newFilteredProducts.filter((product) =>
      currentFilterSettings.gender.slug === 'all'
        ? true
        : product.filterData.gender.find((slug) => slug === currentFilterSettings.gender.slug)
    );

    return [...newFilteredProducts];
  };

  const returnValues = {
    state: {
      ...shopFilterSort,
      // isLoading: shopFilterLoading,
      availableSortOptions: possibleSortOptions,
      availableFilterOptions: possibleFilterOptions,
      dirty: !checkIfQueryMatch(shopFilterSort.current, initialState),
    },
    methods: {
      loadPrevious: () => {
        // TODO
        setShopFilterSort((state) => ({
          ...state,
          current: state.saved,
        }));
      },
      save: async () => {
        // Make this async

        setShopFilterSort((state) => {
          handleClearOrSaveQuery(state.current);
          return {
            ...state,
            saved: state.current,
          };
        });
      },
      sort: (action: SortType) => {
        const { by, order } = action;

        setShopFilterSort((state) => {
          const sorted = handleSortProducts(action, products);
          const filtered = handleFilterProducts(sorted, state.current.filter);
          return {
            ...state,
            current: {
              ...state.current,
              sort: { by, order },
              filteredProducts: filtered,
            },
          };
        });
      },
      /**
       * Insert property from FilterOptionObject and pass a payload (a FilterObject or boolean)
       */
      filter: ({ property, payload }) => {
        setShopFilterSort((state) => {
          let filter: SelectedFilterOptions = state.current.filter;
          if (property === 'gender' || property === 'bundlesOnly' || property === 'excludeBundles') {
            filter = { ...state.current.filter, [property]: payload };
          } else if (property === 'colors') {
            filter = { ...state.current.filter, [property]: [payload] };
          } else {
            // Append new filter to old filter
            const current = state.current.filter[property];
            if (Array.isArray(current)) {
              const newFilter = [...current, payload].reduce((acc, value) => {
                if (acc.find((item: FilterObject) => item.slug === value)) {
                  return acc;
                }
                return [...acc, value];
              }, [] as FilterObject[]);
              filter = { ...state.current.filter, [property]: newFilter };
            }
          }
          const sortedProducts = handleSortProducts(state.current.sort, products);
          const filtered = handleFilterProducts(sortedProducts, filter);
          return {
            ...state,
            current: {
              ...state.current,
              filter: filter,
              filteredProducts: filtered,
            },
          };
        });
      },
      /**
       * Remove with property and slug of FilterObject
       */
      remove: (args) => {
        const { property, slug = '' } = args;

        setShopFilterSort((state) => {
          const current = state.current.filter[property];
          let newFilterState = state.current.filter;
          switch (property) {
            case 'gender':
              newFilterState = { ...newFilterState, [property]: initialGender };
              break;
            case 'bundlesOnly':
            case 'excludeBundles':
              newFilterState = { ...newFilterState, [property]: false };
              break;
            case 'collection':
            case 'sizes':
            case 'colors':
              if (Array.isArray(current)) {
                const newFilter = current.filter((item) => item.slug !== slug);
                newFilterState = { ...newFilterState, [property]: newFilter };
              }
              break;
            default:
              let exhaustiveCheck: never = property;
              console.warn(exhaustiveCheck);
              break;
          }
          const sortedProducts = handleSortProducts(state.current.sort, products);
          const filtered = handleFilterProducts(sortedProducts, newFilterState);

          return { ...state, current: { ...state.current, filteredProducts: filtered, filter: newFilterState } };
        });
      },
      clear: () => {
        handleClearOrSaveQuery(initialState);
        setShopFilterSort({ saved: { ...initialState }, current: { ...initialState } });
      },
    },
  } as ShopFilterContext;

  return <shopFilterContext.Provider value={returnValues}>{children}</shopFilterContext.Provider>;
};

export const useShopFilter = () => {
  const context = useContext<ShopFilterContext | null>(shopFilterContext);
  if (!context) {
    throw new Error('useShopFilter must be used within a ShopFilterProvider');
  }

  return { ...context, state: { ...context.state } };
};

export default ShopFilterProvider;
