import React from 'react';
import { graphql } from 'gatsby';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import OrderConfirmationPage from '~/components/Order/Confirmation/Page';
import { useTranslate } from '~/hooks/utils/use-translate';

const OrderConfirmation = ({ number, id, data: { page } }) => {
  const translate = useTranslate();
  return (
    <Layout>
      <Metadata title={translate('confirmation.page_title')} />
      <OrderConfirmationPage orderNumber={number} page={page} checkoutSessionId={id} />
    </Layout>
  );
};

export default OrderConfirmation;

export const query = graphql`
  query NotAuthorized($locale: String) {
    page: contentfulPage(slug: { eq: "not-authorized" }, node_locale: { eq: $locale }) {
      ...ContentPageFragment
    }
  }
`;
