import { FilterObject, FilterObjectWithAlternate, TransformedFilterSortData } from '~/types/filter';

export const filterSizeMap: Record<string, string[]> = {
  // Sizes will be mapped to other sizes (using presentation property of option value)

  'S-M': ['S', 'M'],

  'L-XL': ['L', 'XL'],

  'L-Tall': ['L', 'XL'],

  Reg: ['XS', 'S', 'M', 'L', 'XL', '2XL'],
};

/**
 * Function that takes a data point or option value and returns a filter object
 */
export const transformToFilterObject = (
  data: Queries.OptionValuesFragmentFragment | Queries.DataPointFragmentFragment
): FilterObject | FilterObjectWithAlternate => {
  const isDataPoint = (
    data: Queries.OptionValuesFragmentFragment | Queries.DataPointFragmentFragment
  ): data is Queries.DataPointFragmentFragment => {
    return data?.__typename === 'ContentfulDataPoint';
  };
  //
  if (isDataPoint(data)) {
    return {
      slug: data.slug ?? '',
      title: data.label ?? '',
    };
  }
  return {
    slug: data.slug ?? '',
    title: data.presentation ?? '',
    alternateRendering: data.alternateRendering ?? '',
  };
};

/**
 * Takes a string array and returns the unique values from the set
 */
const returnUnique = (array: string[]): string[] => {
  const set = new Set(array);
  return set.size === 0 ? [] : Array.from(set);
};

/**
 * Transforms Products into a common format for filtering and sorting
 */
export const mapProductFilterData = (product: Queries.ProductCardFragmentFragment): TransformedFilterSortData => {
  const accessOptionsFromProduct = (property: string, product: Queries.ProductCardFragmentFragment): string[] => {
    const mappedOptions = product?.variants?.map(
      (variant) => variant?.optionValues?.find((option) => option?.option_type?.[0]?.slug === property)?.slug ?? ''
    );

    return returnUnique(mappedOptions ?? []);
  };

  const accessOptionsFromProductForSize = (
    property: string,
    product: Queries.ProductCardFragmentFragment
  ): string[] => {
    const mappedOptions = product?.variants?.map((variant) => {
      const presentationValueSize =
        variant?.optionValues?.find((option) => option?.option_type?.[0]?.slug === property)?.presentation ?? '';
      // Return the mapped size if it exists, otherwise return the presentation value
      return presentationValueSize;
    });

    const mappedWithSubstitutions = mappedOptions?.flatMap((option) => {
      return filterSizeMap[option] ?? option;
    });

    return returnUnique(mappedWithSubstitutions ?? []);
  };
  const isPriceSameAcrossVariants = product.variants?.every((v) => v?.price === product.variants?.[0]?.price);
  const price = isPriceSameAcrossVariants
    ? product.variants?.[0]?.price
    : Math.max(...(product.variants?.map((v) => v?.price ?? 0) ?? [0]));
  const data = {
    price: price ?? 0,
    colors: accessOptionsFromProduct('color', product),
    sizes: accessOptionsFromProductForSize('size', product),
    collections: product.categoryCollectionFilter?.flatMap((dataPoint) => dataPoint?.slug ?? '') ?? [], // todo: change?
    gender: product.collection?.map((item) => item?.slug ?? '') ?? [],
    customValue: product.customValue ?? 0,
    ounceWeight: product.ounceWeight ?? 0,
    isBundle: false,
  };
  return data;
};

/**
 * Transforms Products into a common format for filtering and sorting
 */
export const mapKitFilterData = (kit: Queries.KitCardFragmentFragment): TransformedFilterSortData => {
  const kitProducts = kit.products ?? [];
  const mappedProducts = kitProducts.flatMap((item) =>
    item?.__typename === 'ContentfulProduct' ? mapProductFilterData(item) : []
  );

  return {
    ounceWeight:
      mappedProducts.reduce((acc, value) => {
        return (acc += value.ounceWeight);
      }, 0) ?? 0,
    colors: returnUnique(mappedProducts.flatMap((item) => item.colors)),
    sizes: returnUnique(mappedProducts.flatMap((item) => item.sizes)),
    price: kit.price ?? 0,
    isBundle: true,
    gender: kit.collection?.map((item) => item?.slug ?? '') ?? [],
    collections: returnUnique([...(kit.categoryCollectionFilter?.map((item) => item?.slug ?? '') ?? [])]),
    customValue: kit.customValue ?? 0,
  };
};

export const mapBundleFilterData = (bundle: Queries.BundleCardFragment): TransformedFilterSortData => {
  const bundleProducts = bundle.products ?? [];
  const mappedProducts = bundleProducts.flatMap((item) =>
    item?.__typename === 'ContentfulProduct' ? mapProductFilterData(item) : []
  );

  return {
    ounceWeight:
      mappedProducts.reduce((acc, value) => {
        return (acc += value.ounceWeight);
      }, 0) ?? 0,
    colors: returnUnique(mappedProducts.flatMap((item) => item.colors)),
    sizes: returnUnique(mappedProducts.flatMap((item) => item.sizes)),
    price: bundle.price ?? 0,
    isBundle: true,
    gender: bundle.collection?.map((item) => item?.slug ?? '') ?? [],
    collections: returnUnique([
      ...mappedProducts.flatMap((item) => item.collections),
      ...(bundle.categoryCollectionFilter?.map((item) => item?.slug ?? '') ?? []),
    ]), // Include bundle collections as well
    customValue: bundle.customValue ?? 0,
  };
};
