import { Theme } from 'theme-ui';

const makeTheme = <T extends Theme>(t: T) => t;
export const breakpoints = [768, 1025];

export const oldTheme = makeTheme({
  filter: { darker: 'brightness(80%)' },
  breakpoints: breakpoints.map((br) => `${br}px`), // [768px, 1280px]
  space: [
    0, // 0
    '0.8rem', // 1
    '1.2rem', // 2
    '1.6rem', // 3
    '2.4rem', // 4
    '4.8rem', // 5
    '7.2rem', // 6
    '9.6rem', // 7
    '12rem', // 8
  ],
  fonts: {
    body: 'HCo Gotham, Gotham B, system-ui, sans-serif',
    heading: 'HCo Gotham, Gotham B, system-ui, sans-serif',
    monospace: 'HCo Gotham, Gotham B, monospace',
    primary: 'HCo Gotham, Gotham B, system-ui, sans-serif',
    secondary: 'HCo Gotham, Gotham B, system-ui, sans-serif',
    accentLetter: 'HCo Gotham, Gotham B, system-ui, sans-serif',
  },
  fontSizes: [
    '1rem', // 0
    '1.4rem', // 1
    '1.8rem', // 2
    '2.4rem', // 3
    '4.8rem', // 4
    '9rem', // 5
    '14.5rem', // 6
  ],
  fontWeights: {
    button: 350,
    text: 400,
    medium: 500,
    bold: 700,
    black: 900,
  },
  borderRadius: [
    '0', // 0
    '3rem', // 1
    '4.1rem', // 2
    '8rem', // 3
    '12.4rem', // 4
  ],
  borderBottomRightRadius: [
    '0', // 0
    '3rem', // 1
    '4.1rem', // 2
    '8rem', // 3
    '12.4rem', // 4
  ],
  borderTopLeftRadius: [
    '0', // 0
    '3rem', // 1
    '4.1rem', // 2
    '8rem', // 3
    '12.4rem', // 4
  ],
  styles: {
    root: {
      variant: 'text.body',
      a: { color: 'inherit', textDecoration: 'none', fontFamily: 'inherit' },
    },
  },
  buttons: {
    primary: {
      '--background-color': 'black',
      '--color': 'white',
      borderRadius: '100px',
      color: 'var(--color)',
      backgroundColor: 'var(--background-color)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      fontFamily: 'secondary',
      fontWeight: 'medium',
      minHeight: '4rem',
      fontSize: '1.2rem',
      textTransform: 'capitalize',
      whiteSpace: 'nowrap',
      letterSpacing: '-0.01em',
      lineHeight: 1,
      transition: 'all 0.3s ease-in-out',
      '&:enabled:hover': {
        backgroundColor: [null, 'var(--color)'],
        color: [null, 'var(--background-color)'],
      },
      '&:disabled': {
        cursor: 'default',
      },
    },
    secondary: {
      variant: 'buttons.primary',
      backgroundColor: 'white',
      color: 'black',
      // small: {
      //   variant: 'buttons.primary.small',
      //   backgroundColor: 'olive',
      //   color: 'white',
      // },
      transition: 'all 0.3s ease-in-out',
      '&:enabled:hover': {
        backgroundColor: 'black',
        color: 'white',
      },
    },
    knockout: {
      // '@supports (font-smoothing: antialiased) or (-moz-font-smoothing: greyscale) or (-web-kit-font-smoothing: antialiased) or (-o-font-smoothing: antialiased)':
      //   {
      //     WebkitFontSmoothing: 'antialiased',
      //     MozFontSmoothing: 'greyscale',
      //     OFontSmoothing: 'antialiased',
      //     fontWeight: 800,
      //   },
      variant: 'buttons.primary',
      '--background-color': 'white',
      '--color': 'black',
      small: {
        variant: 'buttons.primary.small',
        '--background-color': 'white',
        '--color': 'black',
      },
    },
    grey: {
      variant: 'buttons.primary',
      backgroundColor: '#cbcbcb',
      color: '#4d4d4d',
      small: {
        variant: 'buttons.primary.small',
        backgroundColor: 'cbcbcb',
        color: '#4d4d4d',
      },
    },
    bare: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minWidth: '3.2rem',
      minHeight: '3.2rem',
      color: 'inherit',
      backgroundColor: 'transparent',
      padding: 0,
      cursor: 'pointer',
      fontFamily: 'body',
    },
    formSubmit: {
      variant: 'buttons.primary',
      color: 'black',
      fontWeight: 'medium',
      backgroundColor: 'white',
    },
    inverted: {
      variant: 'buttons.primary.small',
      '--background-color': 'white',
      '--color': 'black',
      '--border-color': 'black',
      width: '100%',
      maxWidth: '162px',
      height: '36px',
      fontSize: '1.4rem',
      border: `2px solid var(--border-color)`,
    },
  },
  cards: {
    primary: {
      padding: 2,
      borderRadius: 4,
      boxShadow: '0 0 8px rgba(0, 0, 0, 0.125)',
    },
    compact: {
      padding: 1,
      borderRadius: 2,
      border: '1px solid',
      borderColor: 'muted',
    },
  },
  layout: {
    navContainer: {
      '--horizontal-inset': ['1.9rem', '3.5rem', '3.8rem'],
      width: '100%',
      maxWidth: 'calc(1600px - var(--horizontal-inset) * 2)',
      padding: 'var(--horizontal-inset)',
      margin: 'auto',
    },
    customNavContainer: {
      '--horizontal-inset': ['1.9rem', '3.5rem', '3.8rem'],
      width: '100%',
      maxWidth: 'calc(1600px - var(--horizontal-inset) * 2)',
      py: 'var(--horizontal-inset)',
      px: ['3rem', '3rem', '4rem'],
      margin: 'auto',
    },
    containerMargin: {
      width: '100%',
      maxWidth: 'calc(1600px - var(--horizontal-inset) * 2)',
      px: ['1rem', null, '2rem'],
      margin: 'auto',
    },
    container: {
      '--horizontal-inset': ['2.4rem', '9.8rem', '15.4rem'],
      '--width-constraint': '1600px',
      width: '100%',
      maxWidth: '1600px',
      padding: '6.4rem var(--horizontal-inset)',
      margin: 'auto',
    },
    tablet: {
      margin: 'auto',
      maxWidth: 'calc(1600px)',
      padding: [0, null, null],
      overflow: 'hidden',
      width: '100%',
    },
    fullWidth: {
      '--horizontal-inset': ['2.4rem', null, '4rem'],
      '--width-constraint': '1600px',
      padding: [0, null, null],
      overflow: 'hidden',
      width: '100%',
    },
  },
  responsive: {
    mobile: {
      display: ['block', 'none', 'none'],
    },
    mobileTablet: {
      display: ['block', 'block', 'none'],
    },
    tablet: {
      display: ['none', 'block', 'none'],
    },
    desktop: {
      display: ['none', 'none', 'block'],
    },
    tabletDesktop: {
      display: ['none', 'block', 'block'],
    },
  },
  // text for old theme, typography for new theme
  text: {
    body: {
      fontFamily: 'body',
      lineHeight: '145%',
      fontWeight: 'text',
      letterSpacing: '0.2px',
      fontSize: [1, 2],
    },
    small: {
      fontFamily: 'body',
      lineHeight: '13px',
      fontWeight: 'text',
      letterSpacing: '0.2px',
      fontSize: 0,
    },
    large: {
      fontFamily: 'body',
      lineHeight: '34.8px',
      fontWeight: 'text',
      letterSpacing: '0.2px',
      fontSize: [2, '2rem', 3],
    },
    caps: {
      textTransform: 'uppercase',
    },
    formInput: {
      fontFamily: 'body',
      fontSize: 1,
      lineHeight: 'body',
      fontWeight: 'text',
      outline: 'none',
      border: '0',
    },
    formLabel: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'text',
      textTransform: 'uppercase',
    },
    formError: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontSize: 0,
      fontWeight: 'text',
      textTransform: 'uppercase',
      color: 'errorDark',
    },
    link: {
      color: 'inherit',
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'text',
      textDecoration: 'none',
      letterSpacing: '2px',
      textTransform: 'uppercase',
    },
    h1: {
      fontFamily: 'heading',
      lineHeight: '90%',
      fontWeight: 'medium',
      fontSize: ['7.8rem', '11.2rem', 6],
      textTransform: 'uppercase',
    },
    h2: {
      fontFamily: 'heading',
      lineHeight: '90%',
      fontWeight: 'medium',
      fontSize: ['4.4rem', '6.8rem', 5],
      textTransform: 'uppercase',
    },
    h3: {
      fontFamily: 'heading',
      lineHeight: '90%',
      fontWeight: 'medium',
      fontSize: 4,
      textTransform: 'uppercase',
    },
    h4: {
      fontFamily: 'heading',
      lineHeight: '90%',
      fontWeight: 'medium',
      fontSize: ['1.92rem', 3],
      textTransform: 'uppercase',
    },
    h5: {
      fontFamily: 'heading',
      lineHeight: '16.2px',
      fontWeight: 'medium',
      fontSize: 2,
      textTransform: 'uppercase',
    },
    h6: {
      fontFamily: 'heading',
      lineHeight: '12.6px',
      fontWeight: 'medium',
      fontSize: 1,
      textTransform: 'uppercase',
    },
    heading: {
      fontFamily: 'heading',
      fontWeight: 'medium',
      fontSize: '3.6rem',
      lineHeight: 0.9,
      letterSpacing: '-0.01em',
      textTransform: 'uppercase',
    },
    subheading: {
      fontWeight: 'medium',
      fontSize: '1.8rem',
      lineHeight: 0.9,
      letterSpacing: '-0.02em',
      textTransform: 'uppercase',
    },
  },
  list: {
    bare: {
      listStyleType: 'none',
      padding: 0,
    },
  },
});
