import { Text } from 'theme-ui';
import CTAButton from '../../CTAButton';
import ColorComponent from './ColorComponent';

type ColorSelectorProps = {
  isSelected: boolean;
  color: string;
  title: string;
};

const ColorSelector = ({ isSelected, title, color }: ColorSelectorProps) => {
  return (
    <CTAButton
      variant="Outline"
      baseColor="black"
      type="button"
      as="div"
      forwardSx={{
        gap: '3xs',
        alignItems: 'center',
        borderRadius: '2xs',
        border: '1px solid',
        borderColor: isSelected ? 'black !important' : 'cloud',
        p: '3xs',
        backgroundColor: isSelected ? 'black !important' : 'transparent',
        color: isSelected ? 'cloud !important' : 'black',
      }}
    >
      <ColorComponent size="sm" color={color} />
      <Text variant="paragraphSmall">{title}</Text>
    </CTAButton>
  );
};

export default ColorSelector;
