import '~/styles/global.css';
import '~/assets/styles/styles.css';
import './static/fonts/fonts.css';
import React from 'react';
import WrapWithProviders from './src/context/wrap-with-providers';

// https://github.com/gatsbyjs/gatsby/issues/18866#issuecomment-844449917
let nextRoute = ``;

export const onPreRouteUpdate = ({ location }) => {
  nextRoute = location.pathname;
};

export const shouldUpdateScroll = ({ routerProps }) => {
  // Disable scroll by passing this prop in navigation settings
  const { disableScrollUpdate } = routerProps.location.state ?? {};
  if (!disableScrollUpdate || !routerProps.location.hash) return [0, 0];
};

window.addEventListener('unhandledrejection', (event) => {
  if (/loading chunk \d* failed./i.test(event.reason)) {
    if (nextRoute) {
      window.location.pathname = nextRoute;
    }
  }
});

export const wrapRootElement = ({ element }) => <WrapWithProviders element={element} />;
