import { Flex } from 'theme-ui';
import LineItem from '~/components/Cart/LineItem';
import { useCartItems } from '~/utils/use-cart-items';

const OrderConfirmationCart = ({ cart = {} }) => {
  const cartItems = useCartItems(cart);

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: ['center', 'initial'],
        width: '100%',
        maxWidth: ['100%', '40rem'],
        gap: '2rem',
      }}
    >
      {cartItems.map((lineItem) => (
        <LineItem key={lineItem.id} lineItem={lineItem} confirmed />
      ))}
    </Flex>
  );
};

export default OrderConfirmationCart;
