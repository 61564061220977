import { Box, Flex, Text } from 'theme-ui';
import ImgLoader from '~/components/Generic/ImgLoader';
import StarRating from './StarRating';

type Props = {
  card: Queries.AmbassadorCardFragmentFragment;
};
const TestimonialCard = ({ card }: Props) => {
  const { displayName, name, tagline, bio, photo, backgroundColor, starRating, bioTitle } = card;

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',

        cursor: 'pointer',
        ':hover .video-play-button': {
          background: 'white',
          color: 'black',
        },

        ':hover .main-thumbnail': {
          transform: 'scale(1.1) translate3d(0,0,-400px)',
        },
      }}
    >
      <Flex
        sx={{
          flexDirection: 'column',
          width: '100%',
          height: '100%',
          position: 'relative',
          borderRadius: '1.6rem',
          WebkitMaskImage: '-webkit-radial-gradient(white, black)',
          overflow: 'hidden',
        }}
      >
        {photo ? (
          <Box
            sx={{
              width: '100%',
              height: '0px',
              flexShrink: 0,
              position: 'relative',
              pb: 'calc(100% * .602)',
              overflow: 'hidden',
              isolation: 'isolate',
              flex: '0 1 auto',
            }}
          >
            <Box sx={{ height: '100%', width: '100%', position: 'absolute' }}>
              {photo && (
                <div
                  className="main-thumbnail"
                  sx={{
                    position: 'absolute',
                    height: '100%',
                    top: '0%',
                    width: '100%',
                    zIndex: -1,
                    transition: '.3s ease',
                  }}
                >
                  <ImgLoader
                    image={photo}
                    forwardSx={{
                      height: '100%',
                      width: '100%',
                      position: 'relative',
                    }}
                  />
                </div>
              )}
            </Box>
          </Box>
        ) : null}
        <Flex
          sx={{
            backgroundColor: backgroundColor ?? 'cloud',
            width: '100%',
            height: '100%',
            gap: '1.6rem',
            p: ['2.4rem', '3.2rem'],
            pb: ['2.6rem', '3.2rem'],
            flexDirection: 'column',
          }}
        >
          {starRating && <StarRating averageScore={starRating} />}
          <Box
            sx={{ fontSize: '1.6rem', fontWeight: 500, lineHeight: '2.1rem', textTransform: 'uppercase' }}
            dangerouslySetInnerHTML={{ __html: bioTitle ?? '' }}
          />
          <Box
            sx={{ fontSize: ['1.4rem', '1.6rem'], fontWeight: 400, lineHeight: 1.5, p: { m: 0 } }}
            dangerouslySetInnerHTML={{ __html: bio?.childMarkdownRemark?.html ?? '' }}
          />
          <Flex sx={{ flexDirection: 'column' }}>
            {displayName?.childMarkdownRemark?.html ? (
              <Text
                sx={{
                  fontWeight: 'medium',
                  fontSize: '1.4rem',
                  lineHeight: 1,
                  textTransform: 'uppercase',
                }}
                dangerouslySetInnerHTML={{ __html: displayName.childMarkdownRemark.html }}
              />
            ) : (
              <Text sx={{ fontWeight: 'medium', fontSize: '1.4rem', lineHeight: 1, textTransform: 'uppercase' }}>
                {name}
              </Text>
            )}

            <Text
              sx={{
                fontWeight: 300,
                fontSize: '1.4rem',
                lineHeight: 1.5,
              }}
            >
              {tagline}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};

export default TestimonialCard;
