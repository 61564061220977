import { useEffect, useMemo, useState } from 'react';
import combineMedia from '~/utils/combine-media';
import ProductDetails from '~/components/Product/Details';
import PDPWrapper from '~/components/Generic/PDPWrapper';
import { useProductAnalytics } from '~/hooks/utils/customAnalytics';
import { useBadge } from '~/hooks/components/use-badge';

interface ProductPageProps {
  collection:
    | {
        slug: string | null;
        name: string | null;
      }
    | null
    | undefined;
  product: Queries.ProductPageFragmentFragment;
  notifications: {
    pdpNotifications: Queries.NotificationFragmentFragment[];
  };
  productCollection: Queries.ProductCollectionFragment[];
}

const ProductPage: React.FC<ProductPageProps> = ({ collection, product, notifications, productCollection }) => {
  // each product have at least one variant (enforced by Contentful model)
  const [variant, setVariant] = useState<Queries.VariantDetailsFragmentFragment | null>(product.variants?.[0] ?? null);
  const { trackProductViewed } = useProductAnalytics(product.slug);

  useEffect(() => {
    trackProductViewed({ locationDetail: product.name, collection: collection?.slug ?? '' });
  }, [collection?.slug, product.name, trackProductViewed]);

  const { mainImage, images, videos, hdMainImage, hdImages } = variant ?? {};

  const { mediaArrayWithVideos, mediaArrayWithThumbnails } = useMemo(
    () => combineMedia({ mainImage, images, hdImages, hdMainImage, videos }),
    [images, mainImage, videos, hdImages, hdMainImage]
  );

  const { badges } = product;
  const badge = useBadge({ selectedOption: variant, badges: badges as (Queries.BadgeFragment | null | undefined)[] });

  return (
    <PDPWrapper
      selectedVariant={variant}
      product={product}
      variant={variant}
      mediaArrayWithThumbnails={mediaArrayWithThumbnails}
      mediaArrayWithVideos={mediaArrayWithVideos}
      zoomedMediaArrayWithVideos={mediaArrayWithVideos}
      productCollection={productCollection}
      badge={badge}
    >
      <ProductDetails
        notifications={notifications}
        product={product}
        collectionName={collection?.name ?? ''}
        setVariant={setVariant}
        forwardSx={{
          '--horizontal-inset': ['2.4rem', '3.6rem', 0],
          px: 'var(--horizontal-inset)',
          marginBottom: [null, 7, 0],
        }}
      />
    </PDPWrapper>
  );
};

export default ProductPage;
