import React from 'react';
import { Global } from '@emotion/react';
import { Location } from '@reach/router';
import { Script } from 'gatsby';
import { ChordProvider } from '@chordcommerce/react-autonomy';
import GtagProvider from './GtagContext';
import NavigationHeightProvider from './NavigationContext/NavigationHeightContext';
import ConsentManagerWrapper from '~/components/ConsentManagerWrapper';
import UniversalSitePixel from '~/scripts/NBCU/UniversalSitePixel';
import { EmbeddedCheckoutProvider } from './EmbeddedCheckoutContext';
import PageTracker from '~/components/PageTracker';

interface WrapWithProvidersProps {
  element: React.ReactNode;
}

const WrapWithProviders: React.FC<WrapWithProvidersProps> = ({ element }) => {
  const chordConfig = {
    brandName: process.env.GATSBY_CHORD_OMS_BRAND_NAME ?? '',
    storeId: process.env.GATSBY_CHORD_OMS_STORE_ID ?? '',
    omsId: process.env.GATSBY_CHORD_OMS_ID ?? '',
    tenantId: process.env.GATSBY_CHORD_OMS_TENANT_ID ?? '',
    domain: process.env.GATSBY_CHORD_OMS_API_URL ?? '',
    locale: 'en-US',
    currency: 'USD',
  };

  return (
    <>
      <Script src="https://cdn-widgetsrepository.yotpo.com/v1/loader/oYv9wq456iGUWIwK3fxjaKzHpClWABISLeT7xVPL" async />
      {/* @ts-ignore */}
      <ChordProvider config={chordConfig}>
        <ConsentManagerWrapper>
          <GtagProvider>
            <Global
              styles={{
                html: {
                  height: '-webkit-fill-available',
                  fontSize: '62.5%',
                  scrollBehavior: 'smooth',
                  textSizeAdjust: '100%',
                },
                body: {
                  minHeight: '100vh',
                  fontSize: '1.8rem', // 18px
                },
              }}
            />
            <UniversalSitePixel />
            {typeof window !== 'undefined' && (
              <Location>{(locationProps) => <PageTracker location={locationProps.location} />}</Location>
            )}
            <NavigationHeightProvider>
              <EmbeddedCheckoutProvider>{element}</EmbeddedCheckoutProvider>
            </NavigationHeightProvider>
          </GtagProvider>
        </ConsentManagerWrapper>
      </ChordProvider>
    </>
  );
};

export default WrapWithProviders;
